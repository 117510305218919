<template>
  <div
    v-if="data?.show"
    class="promo-banners-container overflow-hidden relative mb-4"
    :class="[
      hasMoreThanOne && !isMobileDevice ? 'rounded-xl' : 'rounded-md',
      isMobileDevice ? 'h-auto' : '',
    ]"
  >
    <div class="w-full relative scoller overflow-x-scroll hardware-accelerate scrollbar-hide">
      <Carousel
        v-if="hasMoreThanOne"
        ref="PromoBannersCarousel"
        :autoplay="autoplayTimer"
        :transition="500"
        pause-autoplay-on-hover
        wrap-around
        class="h-full mx-auto"
        :class="[
          isMobileDevice ? 'h-auto' : '',
          item.backgroundColor ? `bg-${item.backgroundColor} transition-bg-color` : '',
        ]"
        @init="bannerIndex"
        @slide-start="bannerIndex"
        @mouseover="showButtons = true"
        @mouseleave="showButtons = false"
      >
        <Slide v-for="banner in nonExpiredBanners" :key="banner.bannerId">
          <button
            v-if="banner?.show"
            :class="{ 'relative flex justify-end w-full bg-no-repeat bg-center bg-cover overflow-hidden' : banner?.imageLayer }"
            :style="{backgroundImage: banner?.imageLayer ? 'url(' + $sanityImgUrl(banner[isMobileDevice ? 'imageMobile' : 'imageDesktop'].asset._ref) + ')' : '' }"
            class=" h-[160px] sm:h-[265px]"
            type="button"
            @click.prevent.stop="handleLinkTo(banner)"
          >
            <div v-if="banner?.imageLayer" :class="{ 'banner-content-container' : banner?.imageLayer }" class="absolute h-full top-2/4 bg-black/15 translate-y-[-50%] z-10 w-2/3 flex justify-center items-start flex-col left-0 pl-3 py-5 sm:w-3/5 sm:pl-10">
              <p
                v-if="banner?.badgeContent"
                :class="badgeClass(banner.badgeColor)"
                class="text-[10px] mb-1 px-1 pt-0.5 rounded sm:text-[14px] uppercase sm:mb-2 sm:px-2 sm:pb-1"
              >
                {{ banner?.badgeContent }}
              </p>
              <h2
                v-if="banner.promoTitle"
                class="text-left font-bold mb-1 banner-title sm:mb-2 sm:text-[30px]"
              >
                {{ banner.promoTitle }}
              </h2>
              <p class="text-xs text-left mb-1.5 sm:text-base sm:mb-3">{{ banner.promoContent }}</p>
              <ButtonButton
                v-if="banner?.modalButton"
                theme="primary"
                :size="isMobileDevice ? '2xs' : 'sm'"
              >
                {{ banner.modalButton }}
              </ButtonButton>
            </div>
            <div v-if="banner?.imageLayer" class="flex justify-end items-end max-w-max h-full translate-x-20 sm:translate-x-0">
              <img
                :src="$sanityImgUrl(banner['imageLayer'].asset._ref)"
                :class="{ 'aspect-mobile' : isMobileDevice }"
                class="image h-[160px] sm:h-[265px]"
              >
            </div>
            <img
              v-else
              :src="$sanityImgUrl(banner[isMobileDevice ? 'imageMobile' : 'imageDesktop'].asset._ref)"
              :class="{ 'aspect-mobile' : isMobileDevice }"
              class="image h-full"
            >
            <CardRaffleCountdownEnter v-if="banner?.expiryDate" :close-time="banner?.expiryDate"/>
          </button>
        </Slide>
        <template #addons>
          <transition name="fade">
            <ButtonCarousel
              v-if="showButtons"
              direction="left"
              dark
              @click.prevent.stop="PromoBannersCarousel.prev()"
            />
          </transition>
          <transition name="fade">
            <ButtonCarousel
              v-if="showButtons"
              direction="right"
              dark
              @click.prevent.stop="PromoBannersCarousel.next()"
            />
          </transition>
        </template>
      </Carousel>
      <button
        v-else
        :class="{ 'relative flex justify-end w-full bg-no-repeat bg-center bg-cover overflow-hidden' : item?.imageLayer }"
        :style="{backgroundImage: item?.imageLayer ? 'url(' + $sanityImgUrl(item[isMobileDevice ? 'imageMobile' : 'imageDesktop'].asset._ref) + ')' : '' }"
        class="w-full h-[160px] sm:h-[265px]"
        type="button"
        @click.prevent.stop="handleLinkTo(item)"
      >
        <div v-if="item?.imageLayer" :class="{ 'banner-content-container' : item?.imageLayer }" class="absolute h-full top-2/4 bg-black/15 translate-y-[-50%] z-10 w-2/3 flex justify-center items-start flex-col left-0 pl-3 py-5 sm:w-3/5 sm:pl-10">
          <p
            v-if="item?.badgeContent"
            :class="badgeClass(item.badgeColor)"
            class="text-[10px] mb-1 px-1 pt-0.5 rounded uppercase sm:text-[14px] sm:mb-2 sm:px-2 sm:pb-1"
          >
            {{ item?.badgeContent }}
          </p>
          <h2
            v-if="item.promoTitle"
            class="text-left font-bold mb-1 banner-title sm:mb-2 sm:text-[30px]"
          >
            {{ item.promoTitle }}
          </h2>
          <p class="text-xs text-left mb-0 sm:text-base sm:mb-3">{{ item.promoContent }}</p>
          <ButtonButton
            v-if="item?.modalButton"
            theme="primary"
            :size="isMobileDevice ? '2xs' : 'sm'"
          >
            {{ item.modalButton }}
          </ButtonButton>
        </div>
        <div v-if="item?.imageLayer" class="flex justify-end items-end max-w-max h-full translate-x-20 sm:translate-x-0">
          <img
            :src="$sanityImgUrl(item['imageLayer'].asset._ref)"
            :class="{ 'aspect-mobile' : isMobileDevice }"
            class="image h-[160px] sm:h-[265px]"
          >
        </div>
        <img
          v-else
          :src="$sanityImgUrl(item[isMobileDevice ? 'imageMobile' : 'imageDesktop'].asset._ref)"
          :class="{ 'aspect-mobile' : isMobileDevice }"
          class="image h-full  m-auto"
        >
      </button>
    </div>
  </div>
</template>

<script setup>
import { Carousel, Slide } from 'vue3-carousel';
import { storeToRefs } from 'pinia';

import { useAuthStore } from '@/store/auth';
import { useDeviceStore } from '@/store/device';
import { useUiStore } from '@/store/ui';
import { useUserAuth } from '~/composables/useUserAuth';
import { getCurrencyBadgeStyle } from '@/utils/getCurrencyBadgeStyle';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

import 'vue3-carousel/dist/carousel.css';

const props = defineProps({
  page: {
    type: String,
    required: true,
  },
});

const deviceStore = useDeviceStore();
const { isMobileDevice, } = storeToRefs(deviceStore);

const authStore = useAuthStore();
const { isUserLogged, } = storeToRefs(authStore);

const uiStore = useUiStore();
const { chatState, navState, } = storeToRefs(uiStore);
const { openPromoModal, openTxModal, } = uiStore;

const { $sanity, $rollbar, $sanityImgUrl, $dayjs, } = useNuxtApp();
const router = useRouter();
const { handleOpenRegisterModal, } = useUserAuth();

const data = ref({});
const dragging = ref(false);
const item = ref();
const PromoBannersCarousel = ref({});
const showButtons = ref(false);
const newBanner = ref({});
const resizeTimer = ref(null);

const hasMoreThanOne = computed(() => banners.value.length > 1);
const collectionId = computed(() => isUserLogged.value ? props.page : `${props.page}-loggedOut`);
const banners = computed(() => data.value?.banners?.filter(item => item.show));

const nonExpiredBanners = computed(() => {
  return banners.value?.filter((item) => {
    if (!item.expiryDate) {
      return item;
    }

    const now = $dayjs();
    const expiryDate = $dayjs(item.expiryDate);
    const expiresInSeconds = expiryDate.diff(now, 's');
    return expiresInSeconds <= 0 ? null : item;
  }).filter(Boolean);
});

const autoplayTimer = computed(() => !newBanner.value?.autoplayTimer ? 4000 : newBanner.value?.autoplayTimer);

function fetchCmsData() {
  $sanity.fetch(
    `*[_type == "promo-banners-collections" && collectionId == "${collectionId.value}"][0] {
      collectionId,
      page,
      banners[]->,
      show,
    }`
  ).then((res) => {
    data.value = res;
    if (res?.banners?.length) {
      item.value = res.banners[0];
    }
  }).catch((err) => {
    $rollbar.error('PromoBanners fetchCmsData::', err);
  });
}

function badgeClass(badge) {
  let theme = 'default';

  switch (badge) {
    case 'yellow':
      theme = 'DOGE';
      break;
    case 'red':
      theme = 'TRX';
      break;
    case 'green':
      theme = 'USDT';
      break;
    default:
      theme = 'USDC';
      break;
  }

  return getCurrencyBadgeStyle(theme);
}

async function handleLinkTo(item) {
  if (!dragging.value) {
    if (!isUserLogged.value) {
      handleOpenRegisterModal();
    } else {
      const path = item.linkTo;
      if (path.startsWith('action:')) {
        const [, action,] = path.split(':');

        switch (action) {
          case 'deposit':
            openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[0]);
            break;
          case 'buyCrypto':
            openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[2]);
            break;
        }

        return;
      }

      if (path.startsWith('http')) {
        await navigateTo(path, {
          external: true,
          open: {
            target: '_blank',
          },
        });
        return;
      }

      if (item.showModal) {
        openPromoModal(item);
        return;
      }

      router.push({ path, });
    }
  }
}

function handleResize() {
  if (PromoBannersCarousel.value?.updateSlideWidth) {
    PromoBannersCarousel.value.updateSlideWidth();
  }
}

function bannerIndex(bannerIndex) {
  if (!bannerIndex) {
    newBanner.value = banners.value[0];
    return;
  }

  newBanner.value = bannerIndex?.slidingToIndex === bannerIndex?.slidesCount
    ? banners.value[0]
    : banners.value[bannerIndex?.slidingToIndex];
}

watch(
  () => collectionId.value,
  () => {
    fetchCmsData();
  },
  { immediate: true, }
);

watch(
  () => PromoBannersCarousel.value,
  (newVal) => {
    if (!data?.value || !newVal?.data?.currentSlide?.value) {
      return;
    }

    const currentSlide = newVal.data.currentSlide.value;

    if (currentSlide < data.value?.banners?.length) {
      if (currentSlide === -1) {
        item.value = data.value?.banners[data.value?.banners?.length - 1];
      } else {
        item.value = data.value?.banners[currentSlide];
      }
    }
  },
  { immediate: true, deep: true, }
);

watch(
  () => chatState.value,
  () => {
    handleResize();
  }
);

watch(
  () => navState.value,
  () => {
    handleResize();
  }
);

// TODO: Refactor / fix the carousel so we don't need to use a timer when the scrollbar toggles from launching / hiding modals.
onMounted(() => {
  resizeTimer.value = setInterval(() => {
    handleResize();
  }, 50);
});

onBeforeUnmount(() => {
  clearInterval(resizeTimer.value);
});
</script>
<style lang="scss" scoped>
.scoller {
  overflow-y: hidden;
}

.banner-content-container {
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 100%);
}

.banner-title {
  line-height: 1.1;
}

.transition-bg-color {
  transition: background-color 300ms linear;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
}
</style>

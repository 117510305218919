<template>
  <div class="relative -mx-2">
    <nav class="w-full text-white 3xs:rounded-lg lg:hidden mb-2 mt-2 flex flex-nowrap overflow-x-auto scrollbar-hide px-2 gap-1.5">
      <NuxtLink
        v-for="(tab, index) in tabs"
        :key="index"
        class="mobile-tab-link px-2 3xs:px-3 py-2 rounded-lg font-bold flex bg-slate-900 flex-1 justify-center transition-all border whitespace-nowrap"
        :class="route.path === tab.to ? tab.activeClasses : 'border-slate-100/20'"
        :to="tab.to"
        :replace="route.fullPath.includes('sports')"
      >
        <span v-if="tab.iconClass" :class="[tab.iconClass, 'text-xs mr-1.5 scale-[130%]']"/>
        <span class="text-[11px] 3xs:text-xs">{{ tab.text }}</span>
      </NuxtLink>
    </nav>
    <div class="absolute inset-y-0 right-0 w-4 -1 bg-gradient-to-l from-slate-600"/>
    <div class="absolute inset-y-0 left-0 w-2 -1 bg-gradient-to-r from-slate-600"/>
  </div>
</template>

<script setup>
const route = useRoute();

const tabs = [
  {
    text: 'Competitions',
    to: '/',
    iconClass: 'icon-ico-flame text-purple-400',
    tabClasses: 'bg-slate-550',
    activeClasses: 'text-purple-400 border-purple-400',
  },
  {
    text: 'Instant Win',
    to: '/games/',
    iconClass: 'icon-ico-dice-solid text-yellow-500',
    tabClasses: 'bg-slate-550',
    activeClasses: 'text-yellow-500 border-yellow-500',
  },
  {
    text: 'Live',
    to: '/live-games/',
    iconClass: 'icon-ico-user-live-solid text-blue-200',
    tabClasses: 'bg-slate-550',
    activeClasses: 'text-blue-200 border-blue-200',
  },
  {
    text: 'Sports',
    to: '/sports/',
    iconClass: 'icon-sports_soccer text-green-500',
    tabClasses: 'bg-slate-550',
    activeClasses: 'text-green-500 border-green-500',
  },
];
</script>
